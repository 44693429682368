export default {
    gestion: {
        pei:{
            carte: {
                iconText: "{k} {volume_pa} {acces_4x4}"
            },
            plan: "Coordonnées DFCI",
            anomaly_state: {
                er: 'Anomalies disponibles limitées'
            },
        }
    },
    administration: {
        configuration: {
            coverDistanceColor: 'PiYG',
        },
    },
    legend: {
        OSM: "OpenStreetMap",
        SCAN25: "Cartes IGN",
        ORTHO: "Photo aérienne IGN",
    }
}
